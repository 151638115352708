import { SDKInitializationValidationErrors } from '../core/shared/constants/error-codes';
import packageInfo  from '../../package.json';

export class Cashfree {
    constructor() {
        this.randomId = Math.floor(Math.random() * 100000);
    }

    initialiseDropin = (container, config) => {
        if (!config) {
            throw new Error('[initialiseDropin] config missing');
        }
        if (!config.orderToken) {
            throw new Error('[initialiseDropin] orderToken missing in config.');
        }
        if (!config.components) {
            throw new Error('[initialiseDropin] components missing in config.');
        }
        if (typeof config.onSuccess !== 'function') {
            throw new Error(SDKInitializationValidationErrors.PAYMENT_SUCCESS_CALLBACK_MISSING);
        }
        if (typeof config.onFailure !== 'function') {
            throw new Error(SDKInitializationValidationErrors.PAYMENT_FAILURE_CALLBACK_MISSING);
        }
    
        const successCallback = config.onSuccess;
        const failureCallback = config.onFailure;
        const self = this;
    
        window.addEventListener('message', msg => {
            const {orderData, randomId} = msg.data;
            if(self.randomId !== randomId) return;
            if (orderData.order.status === 'PAID') successCallback(orderData);
            else failureCallback(orderData);
        });
    
        const oldFrame = document.getElementById('dropin_frame' + this.randomId);
        let ifrm;
        if(oldFrame) {
            ifrm = oldFrame;
        } else {
            ifrm = document.createElement('iframe');
        }
        // const ifrm = document.createElement('iframe');
        ifrm.setAttribute('allowpaymentrequest', true);
        ifrm.id = "dropin_frame" + this.randomId;
        ifrm.style = `
            width: 100%;
            height: 100%;
            border: none;
            background-color: rgb(248, 250, 247);
        `;
        delete config.onSuccess; // remove this property as it can't be cloned when using postMessage
        delete config.onFailure;
        ifrm.onload = () => {
            const components = [...config.components];
            const orderIndex = components.indexOf('order-details');
            let frameHeight = 0;
            if(orderIndex > -1) {
                if(components.length === 1) {
                    ifrm.style.minHeight = '200px';
                } else {
                    frameHeight += 160;
                }
                components.splice(orderIndex, 1);
            };
            if(components.length === 1) {
                switch(components[0]) {
                    case 'card':
                        ifrm.style.minHeight = frameHeight + 200 + 'px';
                        break;
                    case 'upi-qrcode':
                        ifrm.style.minHeight = '360px';
                        break;
                    case 'upi-collect':
                        ifrm.style.minHeight = frameHeight + 190 + 'px';
                        break;
                    case 'app':
                        ifrm.style.minHeight = (frameHeight > 0 ? frameHeight - 30 : 0) + 475 + 'px';
                        break;
                    case 'netbanking':
                        ifrm.style.minHeight = frameHeight + 410 + 'px';
                        break;
                    case 'upi-intent':
                        ifrm.style.minHeight = frameHeight + 400 + 'px';
                        break;
                    default:
                        ifrm.style.minHeight = '100%';
                }
            } else if(components.length > 1){
                if(components.includes('app')) {
                    ifrm.style.minHeight = (frameHeight > 0 ? frameHeight - 30 : 0) + 575 + 'px';
                } else if(components.includes('netbanking')) {
                    ifrm.style.minHeight = frameHeight + 470 + 'px';
                } else if(components.includes('upi-intent')) {
                    ifrm.style.minHeight = frameHeight + 400 + 'px';
                } else if(components.includes('card')) {
                    ifrm.style.minHeight = frameHeight + 340 + 'px';
                } else if(components.includes('qr-code')) {
                    ifrm.style.minHeight = frameHeight + 340 + 'px';
                } else if(components.includes('upi-collect')) {
                    ifrm.style.minHeight = frameHeight + 240 + 'px';
                }
            }
            config.frameHeight = ifrm.clientHeight;
            config.randomId = self.randomId;
            const { host, pathname, search} = window.location;
            config.parentUrl = host + pathname + search;
            ifrm.contentWindow.postMessage(config, '*')
        };
        ifrm.src = `${REDIRECT_HOST_URL}/dropin/${packageInfo.version}`;
        container.appendChild(ifrm);
    };
}
